import React from 'react'
import Banner from '../../Components/reusable/Banner'
import Layout from '../../Components/reusable/Layout'
import MyCategory from "../../Components/product/category"
function SubCategory() {
    return (
        <Layout>
            <Banner title="Sub Categories" />
<section className="section my-5 py-5">
<div className="container">
    <div className="row my-5">
 
 
        <MyCategory
        title="Sub Category 1"
        content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
        Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
        next="view products"
        link="/products/productdetail"
        />

        <MyCategory
        title="Sub Category 1"
        content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
        Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
        next="view products"
        link="/products/productdetail"
        />

        <MyCategory
        title="Sub Category 1"
        content="Automotive Sprockets are made available by us in a comprehensive array for the automotive industry. 
        Manufactured in custom specifications, the Automotive Sprockets are used in the Vehicles."
        next="view products"
        link="/products/productdetail"
        />
 


 

        </div>

           </div>
       
       
</section>
        </Layout>
    )
}

export default SubCategory
